<template>
  <div v-if="loading">
    <b-overlay rounded="sm">
      <b-card :title="$t('Search for Events')" class="mb-4">
        <div>
          <b-row>
            <b-col md="3">
              <validation-provider #default="validationContext" :name="$t('Search')" rules="min:3">
                <b-form-group :label="$t('Name of the Event')">
                  <b-form-input v-model="search" class="d-inline-block mr-1" :placeholder="$t('Search...')" id="search"
                    :state="getValidationState(validationContext)" />
                </b-form-group>
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <b-form-group :label="$t('Event Type')">
                <v-select :placeholder="$t('filter by Event Type')" v-model="filter.eventtypes"
                  :disabled="searchRequest" :options="filterOptions.eventtypes" name="eventtypes" label="name" multiple
                  :close-on-select="false" />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group :label="$t('Language')">
                <v-select :placeholder="$t('filter by Language')" v-model="filter.languages" :disabled="searchRequest"
                  :options="filterOptions.languages" name="languages" label="name" multiple :close-on-select="false" />
              </b-form-group>
            </b-col>
            <b-col md="3" class="button_add_event">
              <b-button variant="success" :to="{ name: 'apps-events-new', params: {expertid: $route.params.expertid} }">
                <span class="text-nowrap">{{ $t("Add Event") }}</span>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <h4 class="mb-2" v-if="EVENTS.length"> {{ $t('Events') }}</h4>
      <b-row v-if="!searchRequest && EVENTS.length">
        <b-col sm="6" md="6" xl="3" class="mb-2" v-for="(event, index) in EVENTS" :key="index">
          <list-card :event="event" v-on:updateList="getEvents" />
        </b-col>
      </b-row>
      <div class="d-flex justify-content-center mb-1" v-if="searchRequest">
        <b-spinner label="Loading..." />
      </div>
    </b-overlay>
    <b-overlay rounded="sm" v-if="EVENTS.length === 0">
      <h4 class="mb-2"> {{ $t('Events') }}</h4>
      <b-row>
        <b-col sm="6" md="6" xl="3" class="mb-2">
          <div class="image_header">
            <img src="@/assets/images/svg/email.svg" />
          </div>
          <b-card>
            <b-card-body class="expert-events-card-body">
              <h3 class="h3 card-txt text-center py-3 px-2">
                {{ $t('Create your first Event by clicking the Button below') }}
              </h3>
            </b-card-body>
            <b-card-footer class="justify-content-center d-flex">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit" size="sm"
                :to="{ name: 'apps-events-new', params: {expertid: $route.params.expertid} }">
                {{ $t('Create your first Event') }}
              </b-button>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <div class="d-flex justify-content-center">
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit" size="sm" @click="loadMore"
        :class="[(EVENTS_PAGINATION.current_page == EVENTS_PAGINATION.last_page)?'d-none':'']">
        <b-spinner small v-if="loadMoreData" />
        {{ $t('Load more entries') }}
      </b-button>
    </div>
  </div>
</template>
<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardGroup,
  BCarousel,
  BCarouselSlide,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRating,
  BOverlay,
  BRow,
  BTable,
  BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import {mapActions, mapGetters} from "vuex";
import ListCard from "@/views/events/EventList/ListCard";
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, min } from '@validations'

export default {
  directives: {
    Ripple,
  },
  components: {
    ListCard,
    BCardGroup,
    BCard,
    BBadge,
    BAvatar,
    BButton,
    BFormGroup,
    BFormInput,
    BFormRating,
    BTable,
    BCardBody,
    BCardFooter,
    BRow,
    BCarousel,
    BCarouselSlide,
    BCol,
    BOverlay,
    vSelect,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    required,
    min,
  },
  data() {
    return {
      page:1,
      loading: false,
      loadMoreData: false,
      searchRequest:false,
      slide: 0,
      sliding: null,
      interval: 3000,
      items: [],
      search: '',
      lanuagesList: [],
      filter: {
        eventtypes: [],
        languages: []

      },
      filterOptions: {
        eventtypes: [],
        languages: []
      },
    }
  },
  mounted() {
    this.getEvents()
    this.interval = 0
  },

  computed: {
    ...mapGetters(['EVENTS', 'EVENTS_PAGINATION']),
  },
  watch: {
    // whenever question changes, this function will run
    search(newSearchQuery, oldSearchQuery) {
      if ((newSearchQuery != oldSearchQuery) && !this.searchRequest && newSearchQuery.length >= 3) {
        this.searchRequest = true
        this.getEvents()
      } else {
        if (!newSearchQuery.length) {
          this.searchRequest = true
          this.getEvents()
        }
      }
    },
    'filter.eventtypes'(newEventTypes, oldEventTypes) {
      if ((newEventTypes != oldEventTypes) && !this.searchRequest) {
        this.searchRequest = true
        this.getEvents()
      }
    },
    'filter.languages'(newEvenLang, oldEventLang) {
      if ((newEvenLang != oldEventLang) && !this.searchRequest) {
        this.searchRequest = true
        this.getEvents()
      }
    }
  },

  methods: {
    ...mapActions(['GET_EVENTS']),

    loadMore(){
      this.loadMoreData = true
      this.getEvents(this.page+1)
    },
    getEvents(page=1) {
      this.page=page
      this.$http.get('/languages').then((response) => {

        this.lanuagesList = response.data.items
      })
      let searchParams = {
        search: this.search,
        event_types:this.filter.eventtypes,
        event_lang: this.filter.languages
      }
     
      this.GET_EVENTS({ page, searchParams }).then(() => {
        this.loading = true
        this.loadMoreData = false
        this.searchRequest= false
        this.EVENTS.forEach((event) => {
          let eventFilterData = {
            id: event.eventtype.id,
            name: event.eventtype.name
          }
          // event types
          let found = false
          this.filterOptions.eventtypes.forEach((filter) => {
            if (filter.id === eventFilterData.id) found = true
          })
          if (!found) {
            this.filterOptions.eventtypes.push(eventFilterData)
          }
          // event types end
          let foundLanguage = false
          this.filterOptions.languages.forEach((filter) => {
            if (filter.code == event.language) foundLanguage = true
          })
          if (!foundLanguage) {
            let language = this.lanuagesList.find(x => x.code == event.language);
            this.filterOptions.languages.push(language)
          }
        })

        this.filterOptions.eventtypes.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      })
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },


  },


}
</script>
<style lang="scss">
.card-profile {
  padding-top: 7rem;
}

.expert-events-card {
  text-align: center;
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  flex-wrap: wrap;
  min-height: 700px;
}

.profile-badge {
  + .profile-badge {
    margin-left: 0.5rem;
  }
}

.carousel-img > img {
  max-height: 200px !important;
  min-height: 200px !important;
  object-fit: cover !important;
  object-position: center;
  width: 100%;
  height: 100%;
}

.carousel-interval {
  no-wrap: true
}

.slogan-expert {
  min-height: 110px;
}

.event-locationsection {
  min-height: 70px;
}

.carousel-indicators li {
  width: 5px;
  height: 5px;
  border-radius: 100%;
}

.duration {
  text-align: left !important;
  margin-left: 1px;
}

.clock-icon {
  margin-top: 2px;
}

.host-info {
  text-align: center !important;
}

.credits {
  text-align: right !important;
  margin-left: 60px;
}

.minutes {
  margin-left: 5px;
}

.credits-amount {
  margin-left: 5px;
}

.event-location {
  text-align: center !important;
  justify-content: center;
}

.events-duration {
  margin-left: 10px;
}

.events-credits {
  text-align: right !important;
  margin-right: 10px;
}

.carousel .carousel-control-prev,
.carousel .carousel-control-next {
  visibility: hidden;
  opacity: 1;
}

.carousel:hover .carousel-control-prev,
.carousel:hover .carousel-control-next {
  visibility: visible;
  animation-duration: 1s;
  transition-timing-function: linear;
}

.carousel:hover .carousel-control-prev {
  animation-name: FadeInPrev;
}

.carousel:hover .carousel-control-next {
  animation-name: FadeInNext;
}

@keyframes FadeInPrev {
  0% {
    opacity: 0;
    padding-left: 40px;
  }
  100% {
    opacity: 1;
    padding-left: 0px;
  }
}

@keyframes FadeInNext {
  0% {
    opacity: 0;
    padding-right: 40px;
  }
  100% {
    opacity: 1;
    padding-right: 0px;
  }
}

.button_add_event {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: end;
}

.event_img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
}

.image_header {
  background-color: #F1F0FE;
}

</style>
